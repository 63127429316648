import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createPermissionSettingsAction, modifyPermissionSettingsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, types, handleClose } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createPermissionSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyPermissionSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("permissionName")}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* tipo */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type-${data ? data.internalCode : "new"}`}>{getLocalizedString("permissionType")}</InputLabel>
            <Select
              disabled={!isCreate}
              value={formData.type}
              labelId={`label-type-${data ? data.internalCode : "new"}`}
              id={`select-type-${data ? data.internalCode : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                types && types.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* codigo */}
          <TextField
            disabled
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("permissionCode")}
            value={formData.code}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
