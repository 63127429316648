import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionsSettingsAction } from "../actions";
import { getLoading, getPermissions, getTypes } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import Permission from "./publication/permission";
import NoPermissions from "./publication/noPermissions";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";

const PermissionsSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const permissions = useSelector(getPermissions);
  const types = useSelector(getTypes);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getPermissionsSettingsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getPermissionsIsEmpty = () => {
    return !permissions || permissions.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.requestsSettings);
  }

  return (
    <ABMLayout
      title={getLocalizedString("permissionsSettings")}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={getLocalizedString("newPermission")}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Permission
            color={color}
            data={null}
            types={types}
            closeNewPublication={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        permissions && permissions.map((permission) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={permission.internalCode}>
              <Permission data={permission} types={types} color={color} />
            </Grid>
          )
        })
      }
      {
        !isLoading && getPermissionsIsEmpty() &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <NoPermissions
            onClick={() => setCreateIsOpen(true)}
          />
        </Grid>
      }
    </ABMLayout>
  )
}

export default PermissionsSettings;