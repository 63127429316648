import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid, Typography } from "@material-ui/core";
import FabButton from "@icarius-common/fabButton";

const NoPermissions = ({ onClick }) => {

    return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 18, fontWeight: 700, marginBottom: 16 }}>
                {getLocalizedString("noPermissions")}
            </Typography>
            <div style={{ marginTop: 30 }}>
                <FabButton
                    ignoreIcon
                    useColorBackground
                    useWhiteTextAlways
                    onClick={onClick}
                    title={getLocalizedString("newPermission")}
                />
            </div>
        </Grid>
    )
}

export default NoPermissions;